import React, { useState, useEffect, useCallback, useMemo } from "react";
import VehicleButton from "./interactive-elements/VehicleButton";
import VehicleImage from "./interactive-elements/VehicleImage";
import { getItems, getVehicleSide, checkRestyling } from "../../utils/api";
import {
  langStore,
  themeStore,
  tokenStore,
  selectedVehicleStore,
  brandStore,
  modelStore,
  vehicleStore,
  sidePositionStore,
  restylingStore,
  hasOneSideStore,
  selectedRestylingStore,
} from "../../utils/store";
import { SearchButton } from "./interactive-elements/SearchButton";
import SelectComboboxWithSearch from "./interactive-elements/SelectComboboxSearch";
import data from "../assests/json-lang/texts.json";
import { useNavigate } from "react-router-dom";

const Configurator = () => {
  const navigate = useNavigate();

  const [vehicleType, setVehicleType] = useState(
    selectedVehicleStore.get().vehicleType || "auto"
  );
  const [selections, setSelections] = useState({
    brand: selectedVehicleStore.get().brand || null,
    model: selectedVehicleStore.get().model || null,
    vehicle: selectedVehicleStore.get().vehicle || null,
  });

  const [options, setOptions] = useState({
    brands: brandStore.get() || [],
    models: modelStore.get() || [],
    vehicles: vehicleStore.get() || [],
  });
  const [loading, setLoading] = useState({
    brand: false,
    model: false,
    vehicle: false,
  });

  const [emptyResults, setEmptyResults] = useState({
    brand: false,
    model: false,
    vehicle: false,
  });

  const navigateToPage = async () => {
    const vehicle = selections.vehicle;

    if (!vehicle) return; // Nessun veicolo selezionato

    try {
      // Chiamata API per ottenere il lato del veicolo
      const data = await getVehicleSide(
        vehicleType,
        selections.brand.id,
        selections.model.id,
        vehicle.id
      );

      if (data.length === 1) {
        // Se c'è solo un lato, prendi il valore del primo elemento
        const positionId = data[0].value;
        // Salva positionId in uno store o stato se necessario
        sidePositionStore.set(data[0]);
        hasOneSideStore.set(true);

        // Chiamata API per verificare il restyling
        const isRestyling = await checkRestyling(
          vehicleType,
          selections.brand.id,
          selections.model.id,
          vehicle.id,
          positionId
        );

        // Redirigi in base alla risposta
        if (isRestyling.length > 0) {
          restylingStore.set(isRestyling);
          redirectTo("/restyling");
        } else {
          selectedRestylingStore.set("");
          redirectTo("/pagina-prodotti");
        }
      } else {
        // Se ci sono più lati, redirigi alla pagina selezione lato
        hasOneSideStore.set(false);
        sidePositionStore.set("");
        redirectTo("/selezione-lato");
      }
    } catch (error) {
      console.error("Errore durante la navigazione:", error);
    }
  };

  const redirectTo = (path) => {
    const theme = themeStore.get();
    const token = tokenStore.get();

    const queryParams = new URLSearchParams({
      lang,
      theme,
      token,
    }).toString();

    if (window.WIDGET_CONFIG) {
      window.parent.postMessage(
        JSON.stringify({ type: "redirect", url: `${path}?${queryParams}` }),
        "*"
      );
    } else {
      navigate(`${path}?${queryParams}`);
    }
  };

  const lang = langStore.get();
  const dataLanguage = data[lang] || data.it;
  const levels = useMemo(
    () => [
      {
        key: "brand",
        label: dataLanguage.configuratore.label.brand,
        tecdocType: "marche",
        placeholder: dataLanguage.configuratore.placeholder.brand,
      },
      {
        key: "model",
        label: dataLanguage.configuratore.label.model,
        tecdocType: "modelli",
        placeholder: dataLanguage.configuratore.placeholder.model,
      },
      {
        key: "vehicle",
        label: dataLanguage.configuratore.label.vehicle,
        tecdocType: "veicoli",
        placeholder: dataLanguage.configuratore.placeholder.vehicle,
      },
    ],
    [dataLanguage]
  );

  const loadOptions = useCallback(
    async (levelKey, parentId = null) => {
      if (emptyResults[levelKey]) return;

      if (options[`${levelKey}s`]?.length) return; // Evita di caricare dati già presenti
      const levelConfig = levels.find((level) => level.key === levelKey);
      try {
        const data = await getItems(
          levelConfig.tecdocType,
          vehicleType,
          levelKey === "model" ? parentId : selections.brand?.id,
          levelKey === "vehicle" ? parentId : selections.model?.id
        );

        if (data.length === 0) {
          console.warn(`Nessun risultato trovato per il livello ${levelKey}`);
          setEmptyResults((prev) => ({ ...prev, [levelKey]: true })); // Memorizza lo stato di "vuoto"

          return; // Interrompe ulteriori tentativi
        }

        const loadedOptions = data.map((group) => ({
          heading: group.group.name,
          items: group.items.map((item) => ({
            id: item.id,
            name: item.title,
            subTitle: item.subTitle,
          })),
        }));

        setOptions((prevOptions) => ({
          ...prevOptions,
          [`${levelKey}s`]: loadedOptions,
        }));

        if (levelKey === "brand") brandStore.set(loadedOptions);
        else if (levelKey === "model") modelStore.set(loadedOptions);
        else if (levelKey === "vehicle") vehicleStore.set(loadedOptions);
      } catch (error) {
        console.error(`${dataLanguage.configuratore.error}${levelKey}:`, error);
      }
    },
    [
      levels,
      selections.brand?.id,
      selections.model?.id,
      vehicleType,
      options,
      emptyResults,
      dataLanguage.configuratore.error,
    ]
  );
  useEffect(() => {
    const fetchOptions = async () => {
      if (!selections.brand && !loading.brand && !emptyResults.brand) {
        setLoading((prev) => ({ ...prev, brand: true }));
        await loadOptions("brand");
        setLoading((prev) => ({ ...prev, brand: false }));
      }
      if (
        selections.brand &&
        !options.models.length &&
        !loading.model &&
        !emptyResults.model
      ) {
        setLoading((prev) => ({ ...prev, model: true }));
        await loadOptions("model", selections.brand.id);
        setLoading((prev) => ({ ...prev, model: false }));
      }
      if (
        selections.model &&
        !options.vehicles.length &&
        !loading.vehicle &&
        !emptyResults.vehicle
      ) {
        setLoading((prev) => ({ ...prev, vehicle: true }));
        await loadOptions("vehicle", selections.model.id);
        setLoading((prev) => ({ ...prev, vehicle: false }));
      }
    };

    fetchOptions();
  }, [
    vehicleType,
    selections.brand,
    selections.model,
    options.brands.length,
    options.models.length,
    options.vehicles.length,
    loadOptions,
    loading,
    emptyResults,
  ]);

  const handleSelect = (levelKey, selectedOption) => {
    const newSelections = { ...selections, [levelKey]: selectedOption };

    if (levelKey === "brand") {
      newSelections.model = null;
      newSelections.vehicle = null;
      setOptions((prevOptions) => ({
        ...prevOptions,
        models: [],
        vehicles: [],
      }));
      setEmptyResults({ model: false, vehicle: false }); // Resetta lo stato vuoto per i livelli successivi

      loadOptions("model", selectedOption?.id);
    } else if (levelKey === "model") {
      newSelections.vehicle = null;
      setOptions((prevOptions) => ({ ...prevOptions, vehicles: [] }));
      setEmptyResults({ vehicle: false }); // Resetta lo stato vuoto per il livello successivo

      loadOptions("vehicle", selectedOption?.id);
    }

    setSelections(newSelections);

    selectedVehicleStore.set({
      vehicleType,
      brand: newSelections.brand,
      model: newSelections.model,
      vehicle: newSelections.vehicle,
    });
  };

  const updateVehicleType = (type) => {
    setVehicleType(type);
    selectedVehicleStore.set({
      vehicleType: type,
      brand: null,
      model: null,
      vehicle: null,
    });
    setSelections({ brand: null, model: null, vehicle: null });
    setOptions({ brands: [], models: [], vehicles: [] });
    setEmptyResults({ brand: false, model: false, vehicle: false }); // Resetta lo stato vuoto
  };
  return (
    <div className="max-w-5xl mx-auto px-6 lg:px-8 py-8 bg-white rounded-lg min-w-xs">
      <h1 className="text-4xl font-bold text-center mb-12">
        {dataLanguage.configuratore.title}
      </h1>

      <div className="flex justify-center items-center gap-3.5 lg:gap-5 mb-1 border-b">
        {["auto", "truck"].map((type) => (
          <VehicleButton
            key={type}
            type={type}
            isSelected={vehicleType === type}
            onClick={() => updateVehicleType(type)}
            className="text-center hover:fill-primary"
          />
        ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 m-4 lg:mr-8">
        <div className="flex justify-center max-w-full">
          <VehicleImage
            vehicleType={vehicleType}
            className="p-4 object-contain"
          />
        </div>
        <div>
          <div className="space-y-4 w-full mb-8">
            {levels.map((level) => (
              <SelectComboboxWithSearch
                key={level.key}
                label={level.label}
                options={options[`${level.key}s`]}
                selectedOption={selections[level.key]}
                onSelect={(selectedOption) =>
                  handleSelect(level.key, selectedOption)
                }
                disabled={
                  (level.key === "model" && !selections.brand) ||
                  (level.key === "vehicle" && !selections.model)
                }
                placeholder={level.placeholder}
              />
            ))}
            <div
              className={`flex justify-end pt-4 ${
                selections.vehicle
                  ? "opacity-100"
                  : "opacity-0 pointer-events-none"
              }`}
            >
              <SearchButton onclick={navigateToPage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configurator;
