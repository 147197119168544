import {
  langStore,
  tokenStore,
  themeStore,
  selectedVehicleStore,
  serverErrorStore,
  sidePositionStore,
  restylingStore,
  selectedRestylingStore,
} from "../../utils/store";
import data from "../assests/json-lang/texts.json";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { checkRestyling } from "../../utils/api";
import { FilterSelectedVehicle } from "../shared-elements/FilterSelectedVehicle";
import { FilterFittingSide } from "../shared-elements/FilterFittingSide";
import { ServerError } from "../shared-elements/Alerts";

export const Restyling = () => {
  // const [restylings, setRestylings] = useState([]);
  const restylings = restylingStore.get() || [];
  const [serverError, setServerError] = useState(
    serverErrorStore.get() || false
  );

  const navigate = useNavigate();
  // const vehicleData = selectedVehicleStore.get();
  const lang = langStore.get();
  const theme = themeStore.get();
  const token = tokenStore.get();
  const dataLanguage = data[lang] || data.it;
  const queryParams = new URLSearchParams({
    lang,
    theme,
    token,
  }).toString();

  // const selectedSide = sidePositionStore.get();

  const navigateToProducts = async (restyling) => {
    selectedRestylingStore.set(restyling);
    navigate(`/pagina-prodotti?${queryParams}`);
  };

  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     if (!vehicleData?.vehicle?.id) return;
  //     try {
  //       const restyling = await checkRestyling(
  //         vehicleData.vehicleType,
  //         vehicleData.brand.id,
  //         vehicleData.model.id,
  //         vehicleData.vehicle.id,
  //         selectedSide.value
  //       );
  //       if (restyling.length === 0) {
  //         console.warn("Nessun risultato trovato");
  //         return;
  //       }
  //       setServerError(false);
  //       setRestylings(restyling);
  //     } catch (err) {
  //       console.error("Error fetching the products.");
  //       setServerError(true);
  //     }
  //   };

  //   fetchProducts();
  // }, [vehicleData, selectedSide.value]);

  return (
    <div className="max-w-6xl mx-auto px-6 lg:px-8 py-8 bg-white rounded-lg min-w-xs">
      <FilterSelectedVehicle />
      <div className="grid grid-cols-1 md:grid-cols-[280px_1fr] gap-8 mx-8">
        <div>
          <FilterFittingSide />
        </div>

        <div className="mt-10">
          {serverError && <ServerError />}
          <div className=" flex justify-center">
            <h3 className="mx-auto mb-6 text-text_first">
              {dataLanguage.restylingPage.selectStyle}
            </h3>
          </div>
          <div className="flex flex-col items-center justify-center gap-6 lg:flex-row">
            {restylings.map((restyling, index) => {
              const isActive =
                selectedRestylingStore.get()?.value === restyling.value;

              return (
                <button
                  key={index}
                  onClick={() => {
                    navigateToProducts(restyling);
                  }} // Esegui la funzione di redirezione
                  className={`flex flex-col items-center w-72 h-32 gap-4 rounded-lg border ${
                    isActive
                      ? "border-primary border-2 focus:ring-2 focus:ring-primary"
                      : "border-gray-300 hover:border-primary"
                  } justify-center focus:outline-none`}
                >
                  <div className="flex flex-col items-center gap-1">
                    <span className="text-center text-lg text-text_first">
                      {restyling.label}
                    </span>
                    <span className="text-center text-base text-text_gray">
                      {restyling.labelDetail}
                    </span>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
