import { useRef, useState } from "react";
import { DropDownIcon } from "../../configuratore/icons/Icons";

export const Dropdown = ({ buttonLabel, options }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const handleBlur = (e) => {
    if (!dropdownRef.current.contains(e.relatedTarget)) {
      setIsDropdownOpen(false);
    }
  };
  const attributes = [
    ...(options.tecdoc_attributes || []),
    ...(options.custom_attributes || []),
  ];

  return (
    <div
      className="relative"
      ref={dropdownRef}
      onBlur={handleBlur}
      tabIndex={-1}
    >
      <button
        type="button"
        className="px-0.5 py-2 w-full flex justify-between items-center bg-white text-text_first"
        onClick={() => setIsDropdownOpen((prev) => !prev)}
      >
        <span className="text-base text-text_first whitespace-nowrap overflow-hidden  ">
          {buttonLabel.info}
        </span>
        <div className="text-xl flex flex-shrink-0 items-center  ">
          <DropDownIcon className=" text-text_disabled " aria-hidden="true" />
        </div>
      </button>

      {isDropdownOpen && (
        <div className="absolute top-full left-0 bg-white shadow-lg border border-border_card rounded mt-1 z-50 w-full ">
          <div>
            {options.ean && (
              <div className="px-4 py-2 text-sm flex justify-between  items-start gap-2 border-b">
                {" "}
                <span className="font-semibold text-text_first max-w-[60%] inline-block  ">
                  {buttonLabel.eanLabel}
                </span>
                <span className="font-normal text-text_first  ">
                  {options.ean}
                </span>
              </div>
            )}
            {attributes.map((attribute, index) => (
              <div
                key={index}
                className="px-4 py-2 text-sm flex justify-between  items-start gap-2 border-b"
              >
                {" "}
                <span className="font-semibold max-w-[60%] text-text_first inline-block  ">
                  {attribute.label}:
                </span>
                <span className="font-normal text-text_first  break-words text-right ">
                  {attribute.value}
                  {attribute.labelUnit &&
                    attribute.labelUnit !== attribute.value &&
                    ` ${attribute.labelUnit}`}
                </span>
              </div>
            ))}
            {options.family && (
              <div className="px-4 py-2 text-sm flex justify-between  items-start gap-2 border-b">
                {" "}
                <span className="font-semibold max-w-[60%] text-text_first inline-block  ">
                  {buttonLabel.familyLine}
                </span>
                <span className="font-normal text-text_first  ">
                  {options.family}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
