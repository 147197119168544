export const LatoPosteriore = () => {
  return (
    <svg
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 64 32"
      className="w-24"
    >
      <defs>
        <style>
          {`.st0 { fill: #9ea4b0; }
            .st1 { fill: red; }`}
        </style>
      </defs>
      <path
        className="st0"
        d="M57.3,24.7h-5.7c-.3,0-.5-.2-.5-.5v-2.3c0-.3.2-.5.5-.5s.5.2.5.5v1.8h5.1c.8,0,1.7-.4,2.2-1,.4-.5.6-1.2.5-1.9l-2.1-10.7c-.2-1.3-1.2-2.6-2.7-2.6H8.7c-1.5,0-2.4,1.3-2.7,2.6l-2.1,10.7c-.1.7,0,1.3.5,1.9.5.6,1.4,1,2.2,1h6.4c.3,0,.5.2.5.5s-.2.5-.5.5h-6.4c-1.2,0-2.3-.5-3-1.4-.7-.8-.9-1.8-.7-2.8l2.1-10.7c.4-2.1,1.9-3.5,3.7-3.5h46.5c1.8,0,3.4,1.4,3.7,3.5l2.1,10.7c.2,1,0,2-.7,2.8-.7.9-1.9,1.4-3,1.4Z"
      />
      <path
        className="st0"
        d="M38,23.2h-11.3c-.6,0-1-.5-1-1.1v-.9c0-.6.5-1.1,1-1.1h11.3c.6,0,1,.5,1,1.1v.9c0,.6-.5,1.1-1,1.1ZM26.8,21.2v.9s11.2,0,11.2,0v-.9s-11.2,0-11.2,0Z"
      />
      <path
        className="st0"
        d="M51.2,25.7H13.5c-.6,0-1-.4-1-1v-2.9c0-.6.4-1,1-1h12.7c.3,0,.5.2.5.5v.7h11.2s0-.7,0-.7c0-.3.2-.5.5-.5h12.7c.6,0,1,.4,1,1v2.9c0,.6-.4,1-1,1ZM13.6,24.6h37.5v-2.7h-12.1v.2c0,.6-.5,1.1-1,1.1h-11.3c-.6,0-1-.5-1-1.1v-.2h-12.1v2.7Z"
      />
      <path
        className="st1"
        d="M32.1,21.2c-.2,0-.4-.1-.5-.3-.1-.3,0-.6.3-.7l7.1-2.8h-7.2c-.6,0-1-.5-1-1.1v-.9c0-.6.5-1.1,1-1.1h19.5c.6,0,1.1.5,1.1,1.1v.9c0,.6-.5,1.1-1.1,1.1h-9.4l-9.6,3.8c0,0-.1,0-.2,0ZM31.8,14.9v.5s0,.9,0,.9h9.8c0,0,.2,0,.3,0h9.4s0-.9,0-.9h-19.5s0-.5,0-.5Z"
      />
    </svg>
  );
};
