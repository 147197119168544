import {
  langStore,
  tokenStore,
  serverErrorStore,
  sidePositionStore,
  restylingStore,
} from "./store";

export const getItems = async (
  tecdocType,
  vehicleType,
  manufacturerId = null,
  modelId = null
) => {
  if (!tecdocType || !vehicleType) {
    const error = new Error(
      "Missing required parameters: configType, tecdocType, or vehicleType"
    );
    error.statusCode = 400;
    throw error;
  }

  const baseUrl = "https://dev20.vmv-web.it/api";

  const configuratore = "conf-spazzole"; // cambia per altri tipi di configuratori

  const versione = "v1";

  const getTecdoc = {
    marche: "getTecdocManufacturers.php",
    modelli: "getTecdocModels.php",
    veicoli: "getTecdocVehicles.php",
  };

  const token =
    tokenStore.get() ||
    "RoV0Sc6FAQf6ZStiLfHfklgSP6EdYRKWQYF6BcgAavSBt7LDkzlfsZC7D1ytUXvL";
  const waitForLang = async () => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const lang = langStore.get();
        if (lang) {
          clearInterval(interval); // Stop il controllo una volta che lang è disponibile
          resolve(lang);
        }
      }, 100); // Controlla ogni 100ms
    });
  };

  const lang = await waitForLang(); // Aspetta che la lingua sia pronta
  const type = {
    auto: "auto",
    moto: "moto",
    truck: "truck",
  };

  let url = "";

  if (tecdocType === "marche") {
    url = `${baseUrl}/${configuratore}/${versione}/${
      getTecdoc[tecdocType]
    }?token=${encodeURIComponent(token)}&lang=${encodeURIComponent(
      lang
    )}&vType=${encodeURIComponent(type[vehicleType])}`;
  } else if (tecdocType === "modelli" && manufacturerId) {
    url = `${baseUrl}/${configuratore}/${versione}/${
      getTecdoc[tecdocType]
    }?token=${encodeURIComponent(token)}&lang=${encodeURIComponent(
      lang
    )}&vType=${encodeURIComponent(
      type[vehicleType]
    )}&manufacturerId=${manufacturerId}`;
  } else if (tecdocType === "veicoli" && modelId) {
    url = `${baseUrl}/${configuratore}/${versione}/${
      getTecdoc[tecdocType]
    }?token=${encodeURIComponent(token)}&lang=${encodeURIComponent(
      lang
    )}&vType=${encodeURIComponent(type[vehicleType])}&modelId=${modelId}`;
  }
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("An error occurred while fetching the data.");
    }
    serverErrorStore.set(false);

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error loading data:", error);
    if (!error.statusCode) error.statusCode = 500;
    throw error;
  }
};

export const getProducts = async (
  vehicleType,
  manufacturerId,
  modelId,
  vehicleId,
  positionId,
  restylingId
) => {
  if (!vehicleType || !vehicleId) {
    throw new Error("Missing required parameters: vehicleType or vehicleId");
  }
  const baseUrl = "https://dev20.vmv-web.it/api";
  const configuratore = "conf-spazzole";
  const versione = "v1";
  const getTecdoc = "getProducts.php";
  const token = tokenStore.get();

  const lang = await new Promise((resolve) => {
    const interval = setInterval(() => {
      const currentLang = langStore.get();
      if (currentLang) {
        clearInterval(interval);
        resolve(currentLang);
      }
    }, 100);
  });

  const url = `${baseUrl}/${configuratore}/${versione}/${getTecdoc}?token=${encodeURIComponent(
    token
  )}&lang=${encodeURIComponent(lang)}&vType=${encodeURIComponent(
    vehicleType
  )}&manufacturerId=${encodeURIComponent(
    manufacturerId
  )}&modelId=${encodeURIComponent(modelId)}&vehicleId=${encodeURIComponent(
    vehicleId
  )}&restylingId=${encodeURIComponent(
    restylingId
  )}&positionId=${encodeURIComponent(positionId)}`;

  console.log(url, "Generated URL");

  try {
    const response = await fetch(url);
    if (!response.ok) {
      serverErrorStore.set(true);
      throw new Error(`Error: ${response.statusText}`);
    }
    serverErrorStore.set(false);

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const getVehicleSide = async (
  vehicleType,
  manufacturerId,
  modelId,
  vehicleId
) => {
  if (!vehicleType || !vehicleId) {
    const error = new Error(
      "Missing required parameters: vehicleType or vehicleId"
    );
    error.statusCode = 400;
    throw error;
  }
  const baseUrl = "https://dev20.vmv-web.it/api";
  const configuratore = "conf-spazzole";
  const versione = "v1";
  const getTecdoc = "getPositions.php";
  const token =
    tokenStore.get() ||
    "RoV0Sc6FAQf6ZStiLfHfklgSP6EdYRKWQYF6BcgAavSBt7LDkzlfsZC7D1ytUXvL"; // DA RIMUOVERE PRIMA DEL DEPLOY --------------------------
  const waitForLang = async () => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const lang = langStore.get();
        if (lang) {
          clearInterval(interval); // Stop il controllo una volta che lang è disponibile
          resolve(lang);
        }
      }, 100); // Controlla ogni 100ms
    });
  };

  const lang = await waitForLang();

  const url = `${baseUrl}/${configuratore}/${versione}/${getTecdoc}?token=${encodeURIComponent(
    token
  )}&lang=${encodeURIComponent(lang)}&vType=${encodeURIComponent(
    vehicleType
  )}&manufacturerId=${encodeURIComponent(
    manufacturerId
  )}&modelId=${encodeURIComponent(modelId)}&vehicleId=${vehicleId}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      serverErrorStore.set(true);
      throw new Error("Failed to fetch products");
    }
    serverErrorStore.set(false);

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching products: ", error);
    throw error;
  }
};

export const checkRestyling = async (
  vehicleType,
  manufacturerId,
  modelId,
  vehicleId,
  positionId
) => {
  if (!manufacturerId || !positionId) {
    const error = new Error(
      "Missing required parameters: vehicleType or vehicleId"
    );
    error.statusCode = 400;
    throw error;
  }

  const baseUrl = "https://dev20.vmv-web.it/api";
  const configuratore = "conf-spazzole";
  const versione = "v1";
  const getTecdoc = "getRestylings.php";
  const token =
    tokenStore.get() ||
    "RoV0Sc6FAQf6ZStiLfHfklgSP6EdYRKWQYF6BcgAavSBt7LDkzlfsZC7D1ytUXvL"; // DA RIMUOVERE PRIMA DEL DEPLOY --------------------------
  const waitForLang = async () => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const lang = langStore.get();
        if (lang) {
          clearInterval(interval); // Stop il controllo una volta che lang è disponibile
          resolve(lang);
        }
      }, 100); // Controlla ogni 100ms
    });
  };

  const lang = await waitForLang();

  const url = `${baseUrl}/${configuratore}/${versione}/${getTecdoc}?token=${encodeURIComponent(
    token
  )}&lang=${encodeURIComponent(lang)}&vType=${encodeURIComponent(
    vehicleType
  )}&manufacturerId=${encodeURIComponent(
    manufacturerId
  )}&modelId=${encodeURIComponent(modelId)}&vehicleId=${encodeURIComponent(
    vehicleId
  )}&positionId=${encodeURIComponent(positionId)}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      serverErrorStore.set(true);

      throw new Error("Failed to fetch products");
    }
    serverErrorStore.set(false);

    const data = response.json();
    return data;
  } catch (error) {
    console.error("Error fetching products: ", error);
    throw error;
  }
};
