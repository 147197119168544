import AutoImage from "../../assests/vehicle-images/car.jpg";
// import MotoImage from "../assests/vehicle-images/motorbike.jpg";
import TruckImage from "../../assests/vehicle-images/truck.jpg";

const vehicleImages = {
  auto: AutoImage,
  // moto: MotoImage,
  truck: TruckImage,
};

const VehicleImage = ({ vehicleType }) => {
  const imageSrc = vehicleImages[vehicleType] || vehicleImages["Auto"];

  return (
    <img
      src={imageSrc}
      alt={`${vehicleType}`}
      className="w-full h-auto pt-10 md:pb-24 lg:pb-20 md:pt-0 object-contain place-items-center"
    />
  );
};

export default VehicleImage;
