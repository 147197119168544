import data from "../assests/json-lang/texts.json";
import {
  langStore,
  queryStore,
  sidePositionStore,
  hasOneSideStore,
  restylingStore,
  selectedRestylingStore,
} from "../../utils/store";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const FilterFittingSide = () => {
  const navigate = useNavigate();

  const lang = langStore.get();

  const dataLanguage = data[lang] || data.it;
  const selectedSide = sidePositionStore.get();
  const hasOneSide = hasOneSideStore.get();

  const navigateChangeSide = useCallback(() => {
    const queryParams = new URLSearchParams(queryStore.get()).toString();
    selectedRestylingStore.set("");
    navigate(`/selezione-lato?${queryParams}`);
  }, [navigate]);

  return (
    <div className="flex items-left  border-b py-5">
      {/* Icona e descrizione */}

      <div className={`flex ${!hasOneSide ? "w-[280px]" : "w-[280px]"}`}>
        <div
          className={`flex-grow ${
            !hasOneSide ? "basis-[221px]" : "basis-[280px]"
          }`} // Prima colonna
        >
          <div className=" text-sm mr-4 transform translate-y-[+3px]">
            <span className="inline-block		">
              <span className="capitalize text-text_second font-medium inline-block	">
                {dataLanguage.restylingPage.side}:{"\u00A0"}
              </span>
              <span className="capitalize text-text_second font-bold ">
                {selectedSide.label}
              </span>
            </span>
          </div>
        </div>
        {/* Seconda colonna */}
        {!hasOneSide && (
          <div className="flex-grow basis-[59px]">
            <button
              onClick={navigateChangeSide}
              className="text-sm mx-0.5 text-primary  font-medium  cursor-pointer hover:underline hover:underline-offset-4"
            >
              {dataLanguage.restylingPage.edit}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
