import { queryStore } from "./store";
import { useNavigate } from "react-router-dom";

export const sendHeightToHost = () => {
  const height = document.body.scrollHeight;
  const fixedHeight = 800;
  const finalHeight = height < fixedHeight ? fixedHeight : height;

  // Invia il messaggio con l'altezza calcolata
  window.parent.postMessage(
    JSON.stringify({ type: "resize", height: finalHeight }),
    "*"
  );
};

export const resetHeight = () => {
  const height = 800;
  window.parent.postMessage(JSON.stringify({ type: "resize", height }), "*");
};

export const scrollIframeToTop = () => {
  window.parent.postMessage(JSON.stringify({ type: "scrollToIframeTop" }), "*");
};

export const useNavigateToPage = () => {
  const navigate = useNavigate();

  const navigateToPage = () => {
    try {
      // Ottieni i parametri di query dallo store
      const queryParams = new URLSearchParams(queryStore.get()).toString();
      // Naviga all'URL costruito
      navigate(`/?${queryParams}`);

      // Reimposta l'altezza della finestra, se applicabile
      resetHeight && resetHeight();
    } catch (error) {
      console.error("Error during navigation:", error);
    }
  };

  return navigateToPage;
};
