import { SearchIcon } from "../icons/Icons";
import data from "../../assests/json-lang/texts.json";
import { langStore } from "../../../utils/store";

export const SearchButton = ({ onclick }) => {
  const lang = langStore.get();
  const dataLanguage = data[lang] || data.it;
  return (
    <div className="flex place-items-end">
      <button
        onClick={onclick}
        type="button"
        className=" flex space-x-2  rounded-md bg-primary border border-transparent px-3.5 py-2.5 text-sm font-semibold text-white  hover:border hover:border-primary hover:bg-white hover:text-primary "
      >
        <SearchIcon />
        <span>{dataLanguage.searchButton.label}</span>
      </button>
    </div>
  );
};
