import { useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

import {
  langStore,
  tokenStore,
  themeStore,
  queryStore,
  selectedVehicleStore,
} from "./utils/store";
import Configuratore from "./components/configuratore/Configuratore";
import data from "./components/assests/json-lang/texts.json";
import "./index.css";
import { PaginaProdotti } from "./components/prodotti/PaginaProdotti";
import { resetHeight, scrollIframeToTop } from "./utils/functions";
import { SelezioneLato } from "./components/selezione-lato/SelezioneLato";
import { Restyling } from "./components/restyling/Restyling";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const previousPath = useRef(null);

  useEffect(() => {
    const setParamsFromURL = () => {
      const params = new URLSearchParams(window.location.search);
      const lang = params.get("lang");
      const theme = params.get("theme");
      const token = params.get("token")?.split("?")[0]; // Rimuovi tutto dopo il "?"
      langStore.set(lang);
      themeStore.set(theme);
      tokenStore.set(token);
      queryStore.set({ lang, theme, token });

      const title =
        data[lang]?.configuratore?.title || "Configuratore Spazzole";
      document.title = title;

      const description =
        data[lang]?.metaDescription || "Trova le spazzole per il tuo veicolo.";
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute("content", description);
      } else {
        const newMetaDescription = document.createElement("meta");
        newMetaDescription.name = "description";
        newMetaDescription.content = description;
        document.head.appendChild(newMetaDescription);
      }
    };

    const handleRedirection = () => {
      const isPaginaProdotti = location.pathname === "/pagina-prodotti";

      const vehicleData = selectedVehicleStore.get();

      try {
        const isStateValid =
          vehicleData &&
          vehicleData.brand &&
          vehicleData.model &&
          vehicleData.vehicle;

        if (isPaginaProdotti && !isStateValid) {
          const queryParams = new URLSearchParams(queryStore.get()).toString();
          if (window.WIDGET_CONFIG) {
            // Se è un iframe, invia un messaggio al sito host
            window.parent.postMessage(
              JSON.stringify({ type: "redirect", url: `/?${queryParams}` }),
              "*"
            );
            resetHeight();
            scrollIframeToTop();
          } else {
            // Navigazione normale
            navigate(`/?${queryParams}`, { replace: true });
            resetHeight();
            scrollIframeToTop();
          }
        }
        if (
          previousPath.current === "/pagina-prodotti" &&
          location.pathname === "/"
        ) {
          resetHeight();
          scrollIframeToTop();
        }
      } catch (error) {
        console.error("Errore durante il reindirizzamento:", error);
      }
    };

    // Invia altezza al parent quando cambia la navigazione o il contenuto
    // const observer = new MutationObserver(() => {
    //   sendHeightToHost();
    // });

    // observer.observe(document.body, {
    //   childList: true, // Osserva cambiamenti nei figli
    //   subtree: true, // Osserva modifiche a tutti i livelli
    // });

    // // Triggera l'invio iniziale dell'altezza
    // sendHeightToHost();

    setParamsFromURL();
    handleRedirection();
    previousPath.current = location.pathname;

    // return () => observer.disconnect();
  }, [location, navigate]);
  // useEffect(() => {
  //   sendHeightToHost();
  // }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Configuratore />} />
      <Route path="/selezione-lato" element={<SelezioneLato />} />
      <Route path="/restyling" element={<Restyling />} />
      <Route path="/pagina-prodotti" element={<PaginaProdotti />} />
    </Routes>
  );
}

export default App;
