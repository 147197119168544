import {
  langStore,
  tokenStore,
  themeStore,
  queryStore,
  selectedVehicleStore,
  serverErrorStore,
  sidePositionStore,
  restylingStore,
} from "../../utils/store";
import data from "../assests/json-lang/texts.json";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { checkRestyling, getVehicleSide } from "../../utils/api";
import { NoData, ServerError } from "../shared-elements/Alerts";
import { PulsantiLato } from "./PulsanteLato";
import { FilterSelectedVehicle } from "../shared-elements/FilterSelectedVehicle";
import { useNavigateToPage } from "../../utils/functions";

export const SelezioneLato = () => {
  const navigate = useNavigate();
  const navigateToPage = useNavigateToPage();
  const vehicleData = selectedVehicleStore.get();
  const lang = langStore.get();
  const dataLanguage = data[lang] || data.it;

  // Stato per bloccare il rendering
  const [isLoading, setIsLoading] = useState(true);
  const [sides, setSides] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [serverError, setServerError] = useState(
    serverErrorStore.get() || false
  );

  const queryParams = new URLSearchParams(queryStore.get()).toString();

  const activeSide = sidePositionStore.get(); // Ottieni il valore attuale dello store

  useEffect(() => {
    if (serverError === true) {
      setTimeout(() => {
        if (serverError) navigateToPage();
      }, 5000);
    }
  }, [navigateToPage, serverError]);

  const redirectTo = (path) => {
    const theme = themeStore.get();
    const token = tokenStore.get();

    const queryParams = new URLSearchParams({
      lang,
      theme,
      token,
    }).toString();

    if (window.WIDGET_CONFIG) {
      window.parent.postMessage(
        JSON.stringify({ type: "redirect", url: `${path}?${queryParams}` }),
        "*"
      );
    } else {
      navigate(`${path}?${queryParams}`);
    }
  };

  const navigateToProducts = () =>
    redirectTo(`/pagina-prodotti?${queryParams}`);
  const navigateRestyling = (restyling) => {
    restylingStore.set(restyling);
    redirectTo(`/restyling?${queryParams}`);
  };

  const handleSideClick = async (selectedSide) => {
    sidePositionStore.set(selectedSide); // Imposta il valore nello store

    try {
      const isRestyling = await checkRestyling(
        vehicleData.vehicleType,
        vehicleData.brand.id,
        vehicleData.model.id,
        vehicleData.vehicle.id,
        selectedSide.value
      );
      isRestyling.length > 0
        ? navigateRestyling(isRestyling)
        : navigateToProducts();
    } catch (error) {
      console.error("Error checking restyling", error);
    }
  };

  useEffect(() => {
    const unsubscribe = serverErrorStore.subscribe((value) => {
      setServerError(value);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const validateState = () => {
      if (
        !vehicleData ||
        !vehicleData.brand ||
        !vehicleData.model ||
        !vehicleData.vehicle
      ) {
        console.warn(dataLanguage.productsPage.noData);
        setShowAlert(true); // Mostra l'alert

        setTimeout(() => {
          navigateToPage();
        }, 5000);
      } else {
        setIsLoading(false); // I dati sono validi
      }
    };

    validateState();
  }, [vehicleData, navigate, dataLanguage.productsPage.noData, navigateToPage]);

  useEffect(() => {
    const fetchProducts = async () => {
      // Evita di rifare la fetch se isLoading è già true o se sides è stato già caricato
      if (hasFetched || !vehicleData?.vehicle?.id) return; // Esegui la fetch solo se non è stata già fatta

      setIsLoading(true); // Setta isLoading all'inizio della fetch
      try {
        const dataSide = await getVehicleSide(
          vehicleData.vehicleType,
          vehicleData.brand.id,
          vehicleData.model.id,
          vehicleData.vehicle.id
        );

        if (dataSide.length === 0) {
          console.warn("Nessun risultato trovato");
          setShowAlert(true); // Mostra un messaggio d'errore
          setSides([]); // Assicurati che `sides` venga aggiornato a un array vuoto
        } else {
          setSides(dataSide); // Memorizza i lati
          setShowAlert(false); // Nascondi l'alert
        }
      } catch (err) {
        console.error("Error fetching the sides:", err);
        setServerError(true);
      } finally {
        setIsLoading(false); // Assicurati che isLoading venga settato a false alla fine
        setHasFetched(true);
      }
    };

    fetchProducts();
  }, [vehicleData, hasFetched]);

  return (
    <div className="max-w-6xl mx-auto px-6 lg:px-8 py-8 bg-white rounded-lg min-w-xs">
      <FilterSelectedVehicle />
      <div className=" flex justify-center mt-10">
        <h3 className="mx-auto mb-6 text-text_first">
          {dataLanguage.sidePage}
        </h3>
      </div>
      {/* Contenuto principale */}
      {serverError && <ServerError />} {/* server error */}
      {showAlert && <NoData />} {/* no data found */}
      {sides.length > 1 && !isLoading && (
        <PulsantiLato
          sides={sides}
          handleSideClick={handleSideClick}
          activeSide={activeSide}
        />
      )}
    </div>
  );
};
