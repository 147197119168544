import { langStore } from "../../../utils/store";
import data from "../../assests/json-lang/texts.json";

export const ImgInstructionsBox = ({ openModal }) => {
  const lang = langStore.get();

  const dataLanguage = data[lang] || data.it;

  return (
    <div
      className="flex  border-b py-5 transform translate-y-[+3px]"
      onClick={openModal}
    >
      {/* Icona e descrizione */}

      <button className="mx-auto text-sm text-primary font-medium hover:underline hover:underline-offset-4 ">
        {dataLanguage.instructions.imgLink}
      </button>
    </div>
  );
};
