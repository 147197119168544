import { LatoAnteriore } from "../assests/icone/icone-configuratori/LatoAnteriore";
import { LatoPosteriore } from "../assests/icone/icone-configuratori/LatoPosteriore";
import { sidePositionStore } from "../../utils/store";

const iconComponents = {
  A: LatoAnteriore,
  P: LatoPosteriore,
};

export const PulsantiLato = ({ sides, handleSideClick, activeSide }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-6 md:flex-row">
      {sides.map((side, index) => {
        const IconComponent = iconComponents[side.value]; // Seleziona dinamicamente il componente
        const isActive = activeSide?.value === side.value; // Confronta il valore attivo

        return (
          // <div className="w-80 h-">

          <button
            key={index}
            onClick={() => {
              sidePositionStore.set(side);
              handleSideClick(side);
            }} // Esegui la funzione di redirezione
            className={`flex flex-col items-center w-72 h-32 gap-4 rounded-lg border ${
              isActive
                ? "border-primary border-2 focus:ring-2 focus:ring-primary"
                : "border-gray-300 hover:border-primary"
            } justify-center focus:outline-none`}
          >
            <IconComponent />
            <span className="text-center text-lg text-text_first">
              {side.label}
            </span>
          </button>
          // </div>
        );
      })}
    </div>
  );
};
