import { CardSpazzole } from "./interactive-elements/CardProdotti";
import {
  langStore,
  restylingStore,
  selectedRestylingStore,
  selectedVehicleStore,
  serverErrorStore,
  sidePositionStore,
} from "../../utils/store";
import data from "../assests/json-lang/texts.json";
import { SpinningLoading } from "../configuratore/icons/Icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, Fragment } from "react";
import { sendHeightToHost, useNavigateToPage } from "../../utils/functions";
import { getProducts } from "../../utils/api";
import { NoData, NoProducts, ServerError } from "../shared-elements/Alerts";
import { FilterSelectedVehicle } from "../shared-elements/FilterSelectedVehicle";
import { FilterFittingSide } from "../shared-elements/FilterFittingSide";
import { FilterRestyling } from "../shared-elements/FilterRestyling";
import { AdapterArmBox } from "../shared-elements/AdapterArmBox";
import { ImgInstructionsBox } from "../shared-elements/img-instructions/ImgInstructionsBox";
import { ImgModal } from "../shared-elements/img-instructions/ImgModal";
import { VideoInstructionsBox } from "../shared-elements/video-instructions/VideoInstructionsBox";
import { VideoModal } from "../shared-elements/video-instructions/VideoModal";

export const PaginaProdotti = () => {
  const navigate = useNavigate();
  const vehicleData = selectedVehicleStore.get();
  const lang = langStore.get();
  const dataLanguage = data[lang] || data.it;
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Stato per determinare se ci sono più prodotti da caricare
  const [serverError, setServerError] = useState(
    serverErrorStore.get() || false
  );
  const [fittings, setFittings] = useState({});
  const [instructions, setInstructions] = useState("");
  const [isImgModalOpen, setIsImgModalOpen] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const navigateToPage = useNavigateToPage();

  useEffect(() => {
    if (serverError === true) {
      setTimeout(() => {
        if (serverError) navigateToPage();
      }, 5000);
    }
  }, [navigateToPage, serverError]);

  useEffect(() => {
    const validateState = () => {
      if (
        !vehicleData ||
        !vehicleData.brand ||
        !vehicleData.model ||
        !vehicleData.vehicle
      ) {
        console.warn(dataLanguage.productsPage.noData);
        setShowAlert(true); // Mostra l'alert

        setTimeout(() => {
          navigateToPage();
        }, 5000);
      } else {
        setIsLoading(false); // I dati sono validi
      }
    };

    validateState();
  }, [vehicleData, navigate, dataLanguage.productsPage.noData, navigateToPage]);

  // TENTATIVO CARICANDO POSIZIONE E RESTYLING

  const positionId = sidePositionStore.get().value;
  const restylingId = selectedRestylingStore.get()
    ? selectedRestylingStore.get().value
    : "";

  useEffect(() => {
    // let isCancelled = false;
    const fetchProducts = async () => {
      if ((isLoading && !vehicleData?.vehicle?.id) || !hasMore) {
        return;
      }
      setIsLoading(true);
      try {
        const data = await getProducts(
          vehicleData.vehicleType,
          vehicleData.brand.id,
          vehicleData.model.id,
          vehicleData.vehicle.id,
          positionId,
          restylingId
        );
        // if (!isCancelled) {
        //   if (data.products.length === 0) {
        //     setHasMore(false);
        //   } else {
        //     setFittings("");
        //     setServerError(false);
        //     setHasMore(false);
        //     const productsArray = data.products;
        //     setProducts((prevProducts) => [...prevProducts, ...productsArray]);
        //     setFittings(data.fitting);
        //     setInstructions(data.instructions);
        //   }
        //   setIsLoading(false);
        // }
        if (data.length === 0) {
          setIsLoading(false);
          setHasMore(false); // Nessun altro prodotto
        } else {
          setFittings("");
          setIsLoading(false);
          setServerError(false); // Server funziona correttamente
          setHasMore(false); // Nessun altro prodotto
          const productsArray = data.products;
          setProducts((prevProducts) => [...prevProducts, ...productsArray]);
          setFittings(data.fitting);

          setInstructions(data.instructions);
        }
      } catch (err) {
        console.error("Error fetching the products.", err);
        setServerError(true);
      }
    };

    fetchProducts();
    // return () => {
    //   isCancelled = true; // Impedisce aggiornamenti dopo lo smontaggio
    // };
  }, [isLoading, vehicleData, hasMore, positionId, restylingId]);

  console.log(isLoading, "loading");
  console.log(vehicleData, "vehicledata");
  console.log(hasMore, "hasmore");
  console.log(positionId, "positionid");
  console.log(restylingId, "restylingid");

  // Invia altezza iniziale dopo il caricamento degli elementi
  useEffect(() => {
    if (!isLoading) {
      sendHeightToHost();
    }
  }, [isLoading]);

  // Osserva cambiamenti nel contenuto del div con id "div-cards"
  useEffect(() => {
    // Assicurati che il componente non sia in stato di caricamento

    const target = document.getElementById("div-cards");

    if (!target) {
      return;
    }

    const observer = new MutationObserver(() => {
      sendHeightToHost();
    });

    observer.observe(target, {
      childList: true, // Osserva aggiunte o rimozioni di nodi figli
      subtree: false, // Non osserva i discendenti dei figli
    });

    return () => observer.disconnect();
  }, [isLoading]);

  // Aggiungi listener per scroll per gestire il caricamento dinamico
  useEffect(() => {
    const handleScroll = () => {
      sendHeightToHost();
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="max-w-6xl mx-auto px-6 lg:px-8 py-8 bg-white rounded-lg min-w-xs">
      <FilterSelectedVehicle />
      <div className="grid grid-cols-1 md:grid-cols-[280px_1fr] gap-8 mx-8">
        {/* Colonna dei filtri */}
        <div>
          <FilterFittingSide />
          {restylingId !== "" ? <FilterRestyling /> : ""}
          {fittings.length > 0
            ? fittings.map((fitting, index) => (
                <Fragment key={index}>
                  <AdapterArmBox fittings={fitting} index={index} />

                  {/* Gestione delle immagini */}
                  {fitting.instructions.image && (
                    <>
                      <ImgInstructionsBox
                        openModal={() => setIsImgModalOpen(true)}
                      />
                      <ImgModal
                        open={isImgModalOpen}
                        setOpen={setIsImgModalOpen}
                        instructions={fitting.instructions}
                      />
                    </>
                  )}

                  {/* Gestione dei video */}
                  {fitting.instructions.video && (
                    <>
                      <VideoInstructionsBox
                        openModal={() => setIsVideoModalOpen(true)}
                      />
                      <VideoModal
                        open={isVideoModalOpen}
                        setOpen={setIsVideoModalOpen}
                        instructions={fitting.instructions}
                      />
                    </>
                  )}
                </Fragment>
              ))
            : null}
        </div>

        {/* Colonna dei prodotti */}
        <div className="mt-10">
          {serverError && <ServerError />}
          {showAlert && <NoData />} {/* no data found */}
          {products.length === 0 && isLoading && hasMore && !serverError ? (
            <div className="flex justify-center text-center items-center mx-auto py-4">
              <SpinningLoading />
              <span className="ml-4 text-text_second">
                {dataLanguage.productsPage.loading}
              </span>
            </div>
          ) : products.length === 0 && !hasMore && !isLoading ? (
            // no products available
            <div className="flex flex-col items-center justify-center mx-auto py-8">
              <NoProducts navigateToPage={navigateToPage} />
            </div>
          ) : (
            // Griglia dei prodotti
            <div
              id="div-cards"
              className="grid gap-y-8 gap-x-10 lg:grid-cols-2 "
            >
              {products.map((product, index) => (
                <CardSpazzole key={index} product={product} />
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Disclaimer */}
      <p className="mx-6 my-6 text-justify text-text_first">
        {dataLanguage.productsPage.disclaimer}
      </p>
    </div>
  );
};
