import data from "../assests/json-lang/texts.json";
import {
  langStore,
  queryStore,
  restylingStore,
  selectedRestylingStore,
} from "../../utils/store";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const FilterRestyling = () => {
  const navigate = useNavigate();

  const lang = langStore.get();

  const dataLanguage = data[lang] || data.it;
  const restyling = selectedRestylingStore.get();
  const navigateRestyling = useCallback(() => {
    const queryParams = new URLSearchParams(queryStore.get()).toString();
    navigate(`/restyling?${queryParams}`);
  }, [navigate]);

  return (
    <div className="flex items-left border-b py-5">
      {/* Icona e descrizione */}

      <div className="flex w-[280px]">
        <div
          className="flex-grow basis-[221px]"
          // Prima colonna
        >
          <div className="text-sm mr-4 transform translate-y-[+3px]">
            <span className="inline-block		">
              <span className="capitalize text-text_second font-medium  inline-block">
                {dataLanguage.productsPage.restyling}:{"\u00A0"}
              </span>
              <span className="capitalize text-text_second font-bold">
                {restyling.label} {restyling.labelDetail}
              </span>
            </span>
          </div>
        </div>
        {/* Seconda colonna */}
        <div className="flex-grow basis-[59px]">
          <button
            onClick={navigateRestyling}
            className="text-sm mx-0.5 text-primary font-medium  cursor-pointer hover:underline hover:underline-offset-4"
          >
            {dataLanguage.restylingPage.edit}
          </button>
        </div>
      </div>
    </div>
  );
};
