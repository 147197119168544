import { langStore } from "../../utils/store";
import data from "../assests/json-lang/texts.json";

export const AdapterArmBox = ({ fittings }) => {
  const lang = langStore.get();

  const dataLanguage = data[lang] || data.it;

  return (
    <div className="flex items-left border-b py-5">
      {/* Icona e descrizione */}

      <div className="grid grid-cols-[140px_140px]">
        <div className="text-sm  ml-8 transform translate-y-[+3px]">
          <p className="capitalize text-base text-text_second font-medium mt-0.5 ">
            {dataLanguage.adapterArmBox.arm}:{"\u00A0"}
          </p>
          <p className="capitalize text-base text-text_second font-medium  mt-0.5">
            {dataLanguage.adapterArmBox.adapter}:{"\u00A0"}
          </p>
        </div>
        <div className="text-sm mr-4 transform translate-y-[+3px]">
          <p className="capitalize text-lg text-text_second font-bold">
            {fittings.arm}
          </p>
          <p className="capitalize text-lg text-text_second font-bold">
            {fittings.adapter}
          </p>
        </div>
      </div>
    </div>
  );
};
