const VehicleIcon = ({ type }) => {
  const icons = {
    auto: (
      <svg
        id="Layer_1"
        height="2em"
        fill="currentColor"
        viewBox="0 0 90 90"
        width="2em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipRule="evenodd" fillRule="evenodd">
          <path d="m71.82 55.797c2.115 0 3.828 1.708 3.828 3.823 0 2.109-1.713 3.823-3.828 3.823-2.109 0-3.822-1.713-3.822-3.823 0-2.115 1.713-3.823 3.822-3.823zm-53.562 0c2.115 0 3.828 1.708 3.828 3.823 0 2.109-1.713 3.823-3.828 3.823-2.109 0-3.822-1.713-3.822-3.823 0-2.115 1.712-3.823 3.822-3.823zm12.172-10.718h29.225c.801 0 .979 1.093.613 2.15-.781 2.229-2.781 3.724-4.816 3.724h-20.819c-2.037 0-4.037-1.495-4.818-3.724-.37-1.057-.188-2.15.615-2.15zm41.209-2.761c.191-.011.379-.005.566.009 1.35.101 2.465.762 2.918 2.251.775 2.541-1.188 5.5-3.828 5.5h-3.887c-2.77 0-3.479-1.88-3.035-3.213.707-2.136 4.363-4.453 7.266-4.547zm-53.194 0c2.896.094 6.557 2.411 7.266 4.547.443 1.333-.266 3.213-3.037 3.213h-3.885c-2.641 0-4.609-2.959-3.828-5.5.453-1.49 1.568-2.151 2.916-2.251.184-.014.375-.019.568-.009zm26.594-22.843c4.469.005 9.297.172 13.381.473 1.547.115 2.963 1.01 3.916 2.672l5.016 10.641s-12.803 1.375-22.313 1.375c-9.506 0-22.313-1.375-22.313-1.375l5.016-10.641c.953-1.662 2.375-2.557 3.922-2.672 4.084-.302 8.912-.469 13.375-.473zm0-3.47c-5.963 0-8.604-.025-14.432.786-2.699.375-4.646 1.839-5.693 3.803l-5.063 9.542h-6.525c-1.453 0-2.324 1.448-1.943 2.849.416 1.552.885 2.318 3.166 2.842l2.109.491c-.828 1.113-1.734 2.348-2.672 3.577-1.719 2.256-3.713 5.85-3.713 9.298v11.181c0 2.303.615 4.24 2.469 4.964v6.438c0 1.256 1.01 2.261 2.266 2.261h6.422c1.256 0 2.271-1.005 2.271-2.261v-3.754c0-1.533.172-3.162.791-4.766 1.756-4.563 4.041-7.86 8.041-7.86h25.016c4 0 6.281 3.298 8.043 7.86.613 1.604.791 3.233.791 4.766v3.754c0 1.256 1.01 2.261 2.266 2.261h6.428c1.254 0 2.266-1.005 2.266-2.261v-6.438c1.848-.724 2.469-2.662 2.469-4.964v-11.181c0-3.448-2-7.042-3.715-9.298-.938-1.229-1.844-2.464-2.672-3.577l2.109-.491c2.281-.524 2.75-1.29 3.168-2.842.375-1.401-.49-2.849-1.943-2.849h-6.525l-5.068-9.542c-1.041-1.963-2.99-3.428-5.688-3.803-5.83-.811-8.47-.786-14.439-.786z" />
          <path d="m32.533 58.682c-2.656 0-3.891 1.979-5.395 5.902-.434 1.135-.328 3.167.738 3.724h34.328c1.063-.557 1.168-2.589.734-3.724-1.5-3.923-2.734-5.902-5.395-5.902z" />
        </g>
      </svg>
    ),
    moto: (
      <svg
        id="Layer_1"
        height="2em"
        fill="currentColor"
        viewBox="0 0 90 90"
        width="2em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipRule="evenodd" fillRule="evenodd">
          <path d="m52.493 42.078h-14.902v3.432h14.902zm-19.762-.765c-1.328.129-2.651.561-3.615 1.202-1.156.771-1.744 1.673-1.744 2.719v3.864c0 1.548.932 3.6 2.369 5.157.901.979 1.975 1.729 2.99 2.15zm24.62 0v15.093c1.016-.426 2.089-1.172 2.989-2.15 1.434-1.557 2.371-3.609 2.371-5.157v-3.864c0-1.046-.589-1.948-1.746-2.719-.964-.642-2.286-1.074-3.614-1.203zm-23.344-13.58c-1.609 0-2.905 1.303-2.905 2.907 0 1.609 1.296 2.905 2.905 2.905 1.604 0 2.907-1.295 2.907-2.905 0-1.604-1.303-2.907-2.907-2.907zm22.068 0c-1.604 0-2.907 1.303-2.907 2.907 0 1.609 1.303 2.905 2.907 2.905 1.609 0 2.906-1.295 2.906-2.905.006-1.604-1.296-2.907-2.906-2.907zm-11.035-.082c-2.902 0-5.251 2.348-5.251 5.25s2.349 5.254 5.251 5.254c2.905 0 5.254-2.348 5.254-5.254 0-2.902-2.35-5.255-5.254-5.25zm-.064-14.12c-1.781.014-3.546.578-4.995 1.64l-5.516 4.057c2.333 3.567 6.308 5.724 10.574 5.729 4.265-.005 8.238-2.156 10.571-5.729l-5.52-4.057c-1.531-1.126-3.333-1.656-5.114-1.64zm.064-2.953c2.505 0 5.307.994 6.858 2.134l8.989 6.609.922-.967.781-3.042c.276-.901.76-1.699 1.703-1.699h6.443c.942 0 1.354.798 1.093 1.699l-.848 2.973c-.261.907-.693 1.317-1.635 1.317h-5.833l-.927.97c2.621 1.954 3.146 4.53 3.146 6.896 0 4.052-2.104 8.374-5.344 11.213.838.292 1.636.678 2.36 1.161 1.771 1.183 3.172 3.079 3.172 5.391v3.864c0 2.698-1.344 5.303-3.219 7.338-1.469 1.595-3.313 2.871-5.349 3.355v1.459c0 2.192-1.625 3.953-3.667 4.046v-7.739c0-4.708-3.948-8.64-8.645-8.64-4.699 0-8.642 3.943-8.642 8.64v7.739c-2.047-.089-3.667-1.854-3.667-4.046v-1.459c-2.037-.479-3.88-1.76-5.348-3.355-1.876-2.036-3.22-4.64-3.22-7.338v-3.864c0-2.312 1.396-4.208 3.167-5.391.724-.483 1.527-.869 2.365-1.161-3.245-2.839-5.349-7.161-5.349-11.213 0-2.366.532-4.943 3.146-6.896l-.923-.97h-5.838c-.943 0-1.375-.41-1.629-1.317l-.855-2.973c-.26-.901.156-1.699 1.099-1.699h6.442c.937 0 1.428.798 1.699 1.699l.781 3.042.921.967 8.995-6.609c1.553-1.14 4.356-2.134 6.856-2.134z" />
          <path d="m45.04 52.723c-2.71 0-4.839 2.12-4.839 4.833v17.067c0 2.714 2.129 4.845 4.839 4.845 2.713 0 4.838-2.131 4.838-4.845v-17.066c0-2.714-2.126-4.834-4.838-4.834z" />
        </g>{" "}
      </svg>
    ),
    truck: (
      <svg
        id="Layer_1"
        height="2em"
        fill="currentColor"
        viewBox="0 0 90 90"
        width="2em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipRule="evenodd" fillRule="evenodd">
          <path d="m34.001 62.614h22.074l-.407 3.604h-21.26zm33.845-1.369c.958.073 1.756.541 2.078 1.604.552 1.818-.849 3.927-2.734 3.927h-2.771c-1.979 0-2.484-1.345-2.167-2.296.542-1.626 3.474-3.391 5.594-3.235zm-45.609 0c2.12-.156 5.052 1.609 5.593 3.234.313.952-.192 2.296-2.17 2.296h-2.772c-1.886 0-3.285-2.109-2.733-3.927.326-1.062 1.119-1.53 2.082-1.603zm11.015-5.251h23.573l-.407 3.604h-22.76zm.948-6.38h21.678c.703 0 1.859.495 1.421 2.188l-.131 1.177h-24.254l-.136-1.177c-.438-1.693.723-2.188 1.422-2.188zm32.094-19.197 2.703 1.479v15.369l-1.255.688zm-42.473 0-1.448 17.536-1.256-.688v-15.369zm7.019-2.678h28.396c1.313 0 2.817.87 3.005 3.121l.995 11.738h-36.39l.989-11.738c.193-2.251 1.693-3.121 3.005-3.121zm-1.186-17.478c-2.704.438-4.568 2.807-4.839 5.667l-.802 11.719-4.301 2.348-3.407 1.814c-.567.301-1.104.65-1.104 1.462v12.62c0 .813.537 1.162 1.104 1.464l3.407 1.812 2.452 1.344c-3.38 3.375-5.541 5.557-5.547 10.417v8.203c0 2.235.527 4.219 2.438 6.131v2.64c0 1.042.838 1.886 1.887 1.886h5.337c1.048 0 1.885-.844 1.885-1.886v-2.64h33.75v2.64c0 1.042.844 1.886 1.886 1.886h5.344c1.042 0 1.88-.844 1.88-1.886v-2.64c1.917-1.912 2.438-3.896 2.438-6.131v-8.203c0-4.86-2.167-7.042-5.547-10.417l2.453-1.344 3.411-1.812c.568-.303 1.1-.651 1.1-1.464v-12.621c0-.812-.532-1.161-1.1-1.462l-3.411-1.814-4.297-2.348-.801-11.719c-.272-2.86-2.141-5.229-4.838-5.667l-.86 4.083c-.401 1.886-.87 3.375-1.901 4.464-1.031 1.088-2.546 1.557-4.448 1.557h-16.361c-1.901 0-3.421-.469-4.453-1.557-1.025-1.089-1.5-2.579-1.896-4.464z" />
          <path d="m58.257 10.276-.781 3.718c-.369 1.757-.803 2.85-1.36 3.438-.557.59-1.301.887-2.896.887h-16.353c-1.589 0-2.338-.297-2.896-.887-.558-.588-.995-1.681-1.364-3.438l-.786-3.718z" />
        </g>
      </svg>
    ),
  };

  return icons[type] || null;
};

export default VehicleIcon;

export const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1.5em"
      viewBox="0 -960 960 960"
      width="1.5em"
      fill="currentColor"
    >
      <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" />
    </svg>
  );
};

export const DropDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 -960 960 960"
      width="1em"
      fill="currentColor"
    >
      <path d="M480-333 240-573l51-51 189 189 189-189 51 51-240 240Z" />
    </svg>
  );
};

export const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 -960 960 960"
      width="1em"
      fill="currentColor"
    >
      <path d="M389-267 195-460l51-52 143 143 325-324 51 51-376 375Z" />
    </svg>
  );
};

export const FilterAltIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      width="18px"
      height="18px"
      fill="#9ba3af"
      style={{
        width: "27px", // Fissare la larghezza
        height: "27px", // Fissare l'altezza
        display: "block", // Evitare allineamenti strani causati da comportamento inline
      }}
    >
      <path d="M456.18-192Q446-192 439-198.9t-7-17.1v-227L197-729q-9-12-2.74-25.5Q200.51-768 216-768h528q15.49 0 21.74 13.5Q772-741 763-729L528-443v227q0 10.2-6.88 17.1-6.89 6.9-17.06 6.9h-47.88ZM480-498l162-198H317l163 198Zm0 0Z" />
    </svg>
  );
};

export const SpinningLoading = () => {
  return (
    <svg
      className="animate-spin -ml-1 mr-3 h-5 w-5 text-text_first"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};
