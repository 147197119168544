export const LatoAnteriore = () => {
  return (
    <svg
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 64 32"
      className="w-24"
    >
      <defs>
        <style>
          {`.st0 { fill: #9ea4b0; }
            .st1 { fill: red; }`}
        </style>
      </defs>
      <path
        className="st0"
        d="M58.8,26.5h-14.9c-.2,0-.4-.1-.5-.3-.4-.8-1.2-1.3-2.1-1.3s-1.7.5-2.2,1.4c0,.2-.3.3-.5.3h-15.8c-.2,0-.4-.1-.5-.3-.4-.8-1.2-1.3-2.1-1.3s-1.7.5-2.2,1.4c0,.2-.3.3-.5.3H5.2c-.7,0-1.4-.3-1.9-.9-.6-.7-.8-1.8-.6-2.9.6-2.8,1.1-5.4,1.6-8l.3-1.7c.6-2.9,1.2-5.7,1.7-8.4.3-1.6,1.7-2.8,3.2-2.8h45.1c1.4,0,2.9,1.2,3.2,2.8.5,2.7,1.1,5.5,1.7,8.5.4,1.9.8,3.9,1.2,6l.7,3.7c.2,1.1,0,2.2-.6,2.9-.5.6-1.2.9-1.9.9ZM44.2,25.4h14.5c.4,0,.8-.2,1.1-.5.4-.5.5-1.2.4-2l-.7-3.7c-.4-2.1-.8-4.1-1.2-6-.6-3-1.1-5.8-1.7-8.5-.2-1-1.2-1.9-2.1-1.9H9.5c-.9,0-1.9.9-2.1,1.9-.5,2.7-1.1,5.5-1.7,8.4l-.3,1.6c-.5,2.6-1,5.2-1.6,8-.2.8,0,1.5.4,2,.3.3.6.5,1.1.5h12.1c.7-1,1.8-1.6,3-1.6s2.3.6,3,1.6h15.1c.7-1,1.8-1.6,3-1.6s2.3.6,3,1.6Z"
      />
      <path
        className="st1"
        d="M42.4,24c0,0-.1,0-.2,0-.3,0-.4-.4-.3-.7l5.7-17.1c0-.3.4-.4.7-.3.3,0,.4.4.3.7l-5.7,17.1c0,.2-.3.4-.5.4Z"
      />
      <path
        className="st1"
        d="M46.8,21.5c0,0-.2,0-.2,0l-.5-.2c-.7-.2-1.1-1-.8-1.7l4.8-14.3c.1-.3.4-.6.7-.8.3-.2.7-.2,1,0,0,0,0,0,0,0l.5.2c.4.1.7.6.5,1.1l-5.1,15.3c-.2.4-.5.6-.8.6ZM51.4,5.4c0,0-.1,0-.1,0,0,0-.1,0-.1.2l-4.8,14.3c0,.2,0,.3.2.4h.3c0,0,5-14.8,5-14.8h-.3c0-.1,0-.1,0-.1ZM51.9,5.6s0,0,0,0h0Z"
      />
      <path
        className="st0"
        d="M46.1,30.1h-9.7c-.9,0-1.6-.8-1.6-1.7s.8-1.7,1.6-1.7h9.7c.9,0,1.7.7,1.7,1.7s-.7,1.7-1.7,1.7ZM36.4,27.9c-.3,0-.6.3-.6.6s.3.6.6.6h9.7c.3,0,.6-.3.6-.6s-.3-.6-.6-.6h-9.7Z"
      />
      <path
        className="st0"
        d="M44.2,27.9h-5.9c-.3,0-.5-.2-.5-.5,0-2,1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5-.2.5-.5.5ZM38.9,26.8h4.7c-.2-1.1-1.2-1.9-2.4-1.9s-2.1.8-2.3,1.9Z"
      />
      <path
        className="st1"
        d="M21.4,24c0,0-.1,0-.2,0-.3,0-.4-.4-.3-.7l5.7-17.1c0-.3.4-.4.7-.3.3,0,.4.4.3.7l-5.7,17.1c0,.2-.3.4-.5.4Z"
      />
      <path
        className="st1"
        d="M25.8,21.5c0,0-.2,0-.2,0l-.5-.2c-.7-.2-1.1-1-.8-1.7l4.8-14.3c.1-.3.4-.6.7-.8.3-.2.7-.2,1.1,0,0,0,0,0,0,0l.5.2c.4.1.7.6.5,1.1l-5.1,15.3c-.2.4-.5.6-.8.6ZM30.3,5.4c0,0-.1,0-.1,0,0,0-.1,0-.1.2l-4.8,14.3c0,.2,0,.3.2.4h.3c0,0,5-14.8,5-14.8h-.3c0-.1,0-.1,0-.1ZM30.9,5.6s0,0,0,0h0Z"
      />
      <path
        className="st0"
        d="M25.1,30.1h-9.7c-.9,0-1.6-.8-1.6-1.7s.8-1.7,1.6-1.7h9.7c.9,0,1.7.7,1.7,1.7s-.7,1.7-1.7,1.7ZM15.4,27.9c-.3,0-.6.3-.6.6s.3.6.6.6h9.7c.3,0,.6-.3.6-.6s-.3-.6-.6-.6h-9.7Z"
      />
      <path
        className="st0"
        d="M23.2,27.9h-5.9c-.3,0-.5-.2-.5-.5,0-2,1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5-.2.5-.5.5ZM17.9,26.8h4.7c-.2-1.1-1.2-1.9-2.4-1.9s-2.1.8-2.3,1.9Z"
      />
    </svg>
  );
};
